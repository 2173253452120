import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { Spinner, Button, Dropdown } from 'react-bootstrap';
import api from '../../../api';
import { usePusher } from '../../../contexts/PusherContext';
import FakePic from '../../feed/items/FakePic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faExchange } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as ErrorIcon } from '../../../icons/error.svg';
import { ReactComponent as EmailIcon } from '../../../icons/email.svg';
import { ReactComponent as TrashIcon } from '../../../icons/trash.svg';
import { ReactComponent as UpCaretIcon } from '../../../icons/up-caret.svg';
import { ReactComponent as DownCaretIcon } from '../../../icons/down-caret.svg';
import { ReactComponent as EmptyProspectsIcon } from '../../../icons/empty-prospects.svg';
import { ReactComponent as QuestionMagnifyIcon } from '../../../icons/question-magnify.svg';

import '../campaign-new.css';
import MissingCompaniesModal from './MissingCompaniesModal';
import FakeBatch from './FakeBatch';

function ProspectsList({
  embedded,
  nextText,
  nextFn,
  prospects,
  setProspects,
  canSearch,
  forceRunSummaryFetch,
  isInitialCheck,
}) {
  const { id: oagentId } = useParams();
  const pusherChannel = usePusher();

  const { getAccessTokenSilently } = useAuth0();

  const [error, setError] = useState(null);
  const [oagents, setOagents] = useState([]);
  const [runSummary, setRunSummary] = useState(null);
  const [stoppingImport, setStoppingImport] = useState(null);
  const [missingCompanies, setMissingCompanies] = useState([]); // State to hold missing companies
  const [showMissingCompaniesModal, setShowMissingCompaniesModal] =
    useState(false);

  const [visibleBatches, setVisibleBatches] = useState([
    'High relevance',
    'Good relevance',
  ]);

  const moveTo = async (new_oagent_id, prospect_id) => {
    const tmp = prospects.find((x) => x.id === prospect_id);
    try {
      // Remove first optimistically
      setProspects((prospects) => {
        return prospects.filter((x) => x.id !== prospect_id);
      });
      const token = await getAccessTokenSilently();
      await api.post(
        `/oagents/${oagentId}/move-prospect/${prospect_id}`,
        {
          new_oagent_id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    } catch (error) {
      console.error(`Error moving prospect `, error);
      setProspects((prospects) => {
        return [...prospects, tmp];
      });
    }
  };

  // Use for the move dropdown
  const fetchOagents = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/oagents`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setOagents(
        response.data ? response.data.filter((x) => x.id !== oagentId) : [],
      );
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data.');
    }
  };

  const fetchDetails = async () => {
    try {
      const token = await getAccessTokenSilently();
      const [runSummaryResponse, missingCompaniesResponse] = await Promise.all([
        api.get(`/oagents/${oagentId}/import-run-summary/prospects`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        api.get(`/oagents/${oagentId}/companies-missing-prospects`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);
      setRunSummary(runSummaryResponse.data);
      setMissingCompanies(missingCompaniesResponse.data);
      setStoppingImport(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data.');
    }
  };

  const fetchMissingCompanies = async () => {
    try {
      const token = await getAccessTokenSilently();
      const missingCompaniesResponse = await api.get(
        `/oagents/${oagentId}/companies-missing-prospects`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setMissingCompanies(missingCompaniesResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data');
    }
  };

  useEffect(() => {
    fetchDetails();
    fetchOagents();
  }, [forceRunSummaryFetch]);

  useEffect(() => {
    fetchDetails();

    if (!pusherChannel) return;
    const pusherCallback = (data) => {
      if (parseInt(data.oagentId, 10) === parseInt(oagentId, 10)) {
        if (data.sub_type === 'new_prospect' && data.prospect) {
          setProspects((prevOrgs) => {
            // Just check we haven't already listed them
            const prevEntry = prevOrgs.some((x) => x.id == data.prospect.id);

            if (!prevEntry) {
              return [...prevOrgs, data.prospect];
            } else if (prevEntry.status === 'inactive') {
              return [
                ...prevOrgs.filter((x) => x.id !== prevEntry.id),
                data.prospect,
              ];
            } else {
              return prevOrgs;
            }
          });
        } else if (data.sub_type === 'import_run_update') {
          setRunSummary(data.prospect_summary);
        }

        // We'll call this as we don't get the data back from the backend
        fetchMissingCompanies();
      }
    };

    pusherChannel.bind('oagent_status_update', pusherCallback);

    // Cleanup on unmount
    return () => {
      pusherChannel.unbind('oagent_status_update', pusherCallback);
    };
  }, [getAccessTokenSilently, oagentId, pusherChannel]);

  const handleDelete = async ({ id }) => {
    setError(null); // Reset error before deleting
    try {
      const token = await getAccessTokenSilently();
      const idsToDelete = [id];

      // Optimistically remove the selected rows
      setProspects(
        prospects.filter((prospect) => !idsToDelete.includes(prospect.id)),
      );

      await api.post(
        `/oagents/${oagentId}/deactivate-prospects`,
        { ids: idsToDelete },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    } catch (error) {
      console.error('Error during delete:', error);
      setError('Error during delete.');
    }
  };

  const stopImport = async () => {
    try {
      setStoppingImport(true);
      const token = await getAccessTokenSilently();

      await api.post(
        `/oagents/${oagentId}/stop-imports`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      fetchDetails();
    } catch (error) {
      console.error('Error during delete:', error);
      setError('Error during delete.');
    }
  };

  const processingOrWaiting =
    runSummary &&
    runSummary.status_counts
      .filter(
        (item) => item.status === 'processing' || item.status === 'waiting',
      )
      .reduce((sum, item) => sum + parseInt(item.count, 10), 0);

  const totalOrgs = runSummary?.filtered_orgs_count;

  const getStatus = (row) => {
    let statusText;
    if (row.status === 'placeholder') {
      return;
    }

    if (row.status === 'inactive') {
      return <p className='status-tag negative'>{row.status_explainer}</p>;
    }

    if (row.status === 'outreaching' && row.conversation_status !== null) {
      const cs =
        row.conversation_status.slice(0, 1).toUpperCase() +
        row.conversation_status.slice(1).toLowerCase();

      let add;
      if (cs === 'Fail') {
        add = 'negative';
      } else if (cs === 'Contacted') {
        add = 'neutral';
      } else if (cs === 'Replied') {
        add = 'positive';
      } else if (cs === 'Booked') {
        add = 'dream-positive';
      } else {
        add = 'positive';
      }

      return <p className={`status-tag ${add}`}>{cs}</p>;
    }

    if (row.status === 'outreaching' && row.conversation_status === null) {
      if (row.autopilot_status) {
        statusText = 'Outreaching';
      } else {
        statusText = 'Ready';
      }
      return <p className='status-tag neutral'>{statusText}</p>;
    }

    statusText = row.status.charAt(0).toUpperCase() + row.status.slice(1);
    if (row.status_explainer) {
      statusText += ` - ${row.status_explainer}`;
    }

    return <p className='status-tag neutral'>{statusText}</p>;
  };

  const batchup = (prospects) => {
    if (!prospects) return;
    const batches = [
      {
        min: 0.81,
        max: 1.0,
        name: 'High relevance',
        className: 'mega-positive',
        prospects: [],
      },
      {
        min: 0.51,
        max: 0.8,
        name: 'Good relevance',
        className: 'positive',
        prospects: [],
      },
      {
        min: 0.0,
        max: 0.5,
        name: 'Low relevance',
        className: 'negative',
        prospects: [],
      },

      {
        no_email: true,
        name: 'No email',
        className: 'neutral',
        prospects: [],
      },
    ];

    prospects.forEach((prospect) => {
      if (!prospect.email) {
        const rI = batches.findIndex((x) => x.no_email);
        if (rI !== false) {
          batches[rI].prospects.push(prospect);
        }
        return;
      }

      for (const batch of batches) {
        if (
          prospect.relevance >= batch.min &&
          prospect.relevance <= batch.max
        ) {
          batch.prospects.push(prospect);
          break;
        }
      }
    });

    return batches;
  };

  const batches = batchup(prospects);

  const toggleVisible = (batchName) => {
    setVisibleBatches((batches) => {
      if (batches.indexOf(batchName) !== -1) {
        return batches.filter((x) => x !== batchName);
      } else {
        return [...batches, batchName];
      }
    });
  };

  const missingCompaniesFormat = () => {
    const intM = missingCompanies.map((x) => x.name);

    if (intM.length === 1) {
      return <b>{intM[0]}</b>;
    } else if (intM.length === 2) {
      return (
        <>
          <b>{intM[0]}</b> and <b>{intM[1]}</b>
        </>
      );
    } else if (intM.length === 3) {
      return (
        <>
          <b>
            {intM[0]}, {intM[1]}
          </b>{' '}
          and <b>{intM[2]}</b>
        </>
      );
    } else {
      return (
        <>
          <b>
            {intM[0]}, {intM[1]}
          </b>{' '}
          and <b>{intM.length - 2} others</b>
        </>
      );
    }
  };

  return (
    <div className={`page-wrapper prospect-list-page`}>
      <div className='page-inner-wrapper'>
        <div className='header'>
          <div className='left'>
            <h5>
              {prospects.length > 0 && (
                <>
                  <b>{prospects.length} prospects</b> in campaign
                </>
              )}
              {prospects.length === 0 && <>Prospect list</>}
            </h5>
          </div>
          {!processingOrWaiting &&
            embedded &&
            nextText &&
            prospects.length > 0 && (
              <div className='right'>
                <Button variant='primary' onClick={nextFn}>
                  Next: {nextText}{' '}
                  <div className='yellow-icon'>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </Button>
              </div>
            )}
          {processingOrWaiting !== 0 && processingOrWaiting <= totalOrgs && (
            <div className='processing-set'>
              <Spinner />
              {processingOrWaiting}/{totalOrgs} companies to check
              {stoppingImport ? (
                <span className='killswitch-engaged'>Attempting Stop...</span>
              ) : (
                <span
                  onClick={stopImport}
                  className='stop-processing-killswitch'
                >
                  Stop searching
                </span>
              )}
            </div>
          )}
          {/* The below can happen when multiple queue items get input for a company. This can happen if there's an error in an earlier run or similar. */}
          {processingOrWaiting !== 0 && processingOrWaiting > totalOrgs && (
            <div className='processing-set'>
              <Spinner />
              {processingOrWaiting} checks in progress
              {stoppingImport ? (
                <span className='killswitch-engaged'>Attemptin Stop...</span>
              ) : (
                <span
                  onClick={stopImport}
                  className='stop-processing-killswitch'
                >
                  Stop searching
                </span>
              )}
            </div>
          )}
        </div>
        {error && (
          <div className='big-error-banner'>
            <ErrorIcon /> {error}
          </div>
        )}
        {processingOrWaiting !== 0 && !isInitialCheck && (
          <>
            <div className='nice-progress-bar'>
              <div
                className='filler'
                style={{
                  width: `${processingOrWaiting <= totalOrgs ? ((totalOrgs - processingOrWaiting) / totalOrgs) * 100 : 10}%`,
                }}
              ></div>
            </div>
            <div className='progress-description'>
              <p>
                Prospects are scored based on how relevant they are to your
                context and job titles. Where we can’t find a matching prospect
                in a company we’ll use your context to find job titles with
                similar responsibility.
              </p>
              <p>
                High and Good relevance prospects will automatically be pulled
                through to your campaign, you’ll need to opt in to add Low
                relevance prospects.
              </p>
            </div>
          </>
        )}

        {prospects.length === 0 &&
          processingOrWaiting === 0 &&
          !isInitialCheck && (
            <div className='prospect-empty-state'>
              <EmptyProspectsIcon />
              <h6>Find your perfect prospects</h6>
              <p>
                Dream’s prospect search explores deeper than just job titles.
                Add your search and setup fallbacks to make sure you never miss
                a potential prospect.
              </p>
            </div>
          )}
        {isInitialCheck && (
          <div className='prospect-batches'>
            <FakeBatch count={4} />
          </div>
        )}
        {prospects.length > 0 && (
          <div className='prospect-batches'>
            {batches
              .filter((x) => x.prospects.length > 0)
              .map((batch) => {
                const prospects = batch.prospects;
                const visible = visibleBatches.find((x) => x === batch.name);

                return (
                  <div
                    className={`batch ${visible && 'expanded'} ${batch.className}`}
                    key={batch.name}
                  >
                    <div
                      className='batch-title'
                      onClick={() => toggleVisible(batch.name)}
                    >
                      <div className='card-left'>
                        <div className='dot'></div>
                        <p>
                          {batch.name} ({batch.prospects.length})
                        </p>
                      </div>
                      {visible && <DownCaretIcon />}
                      {!visible && <UpCaretIcon />}
                    </div>
                    {visible && (
                      <>
                        {prospects.map((prospect) => {
                          return (
                            <div className='prospect-view' key={prospect.id}>
                              <div
                                className='logo'
                                onClick={() =>
                                  prospect.linkedin_url
                                    ? window.open(prospect.linkedin_url)
                                    : null
                                }
                              >
                                <FakePic url={prospect.logo_url} />
                              </div>
                              <div className='center-matter'>
                                <p className='name'>{prospect.name}</p>
                                <p className='title'>
                                  {prospect.job_title || 'N/A'} at{' '}
                                  {prospect.org_name}
                                </p>
                                <p className='reasoning'>
                                  {prospect.reasoning}
                                </p>
                              </div>
                              <div className='actions-area'>
                                <div className='email-icon-wrapper'>
                                  <EmailIcon />
                                </div>
                                {getStatus(prospect)}
                                <div className='move-campaign'>
                                  <Dropdown>
                                    <Dropdown.Toggle>
                                      <FontAwesomeIcon icon={faExchange} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {oagents.map((oagent) => (
                                        <Dropdown.Item
                                          onClick={() =>
                                            moveTo(oagent.id, prospect.id)
                                          }
                                          key={oagent.id}
                                        >
                                          Move to {oagent.name}
                                        </Dropdown.Item>
                                      ))}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                                <div
                                  className='remove-prospect'
                                  onClick={() => handleDelete(prospect)}
                                >
                                  <TrashIcon />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                );
              })}
          </div>
        )}

        {missingCompanies.length > 0 &&
          processingOrWaiting === 0 &&
          canSearch && (
            <div className='missing-companies-line'>
              <div>
                <QuestionMagnifyIcon />
                <span>
                  No prospects retrieved for {missingCompaniesFormat()}
                </span>
              </div>
              {missingCompanies.length > 3 && (
                <span
                  className='open-missing-modal'
                  onClick={() => setShowMissingCompaniesModal(true)}
                >
                  Show all companies
                </span>
              )}
            </div>
          )}
      </div>

      {showMissingCompaniesModal && (
        <MissingCompaniesModal
          show
          handleClose={() => setShowMissingCompaniesModal(false)}
          missingCompanies={missingCompanies}
        />
      )}
    </div>
  );
}

export default ProspectsList;
