const predefinedResearchPoints = [
  {
    name: 'Have they announced a new {thing}?',
    type: 'campaign_trigger',
    prefill: {
      name: ({ thing }) => `${thing} Announced`,
      definition: ({ thing }) => `Have they announced a new ${thing}.`,
      example: 'Yes',
      time_measure: 'after',
      time_value: ({ timeframe }) => timeframe,
    },
    additional_options: {
      thing: {
        input_type: 'text',
        description: `The subject of the announcement`,
        long_description: `For example Office Move, Profit Warning, Relocation`,
      },
      timeframe: {
        input_type: 'date',
        format: 'YYYY-MM-DD',
        description: `How long back to look`,
      },
    },
    sources: ['google', 'openai'],
  },
  {
    name: '{job title} hired in the last {x} months?',
    type: 'campaign_trigger',
    prefill: {
      name: ({ job_title }) => `${job_title} Hired`,
      definition: ({ job_title }) => `Have they hired a ${job_title}.`,
      example: 'Jane Smith',
      time_measure: 'after',
      time_value: ({ timeframe }) => timeframe,
    },
    additional_options: {
      job_title: {
        input_type: 'text',
        description: `The job title you're looking for`,
        long_description: `For example CEO, CFO, Chairman, CTO`,
      },
      timeframe: {
        input_type: 'date',
        format: 'YYYY-MM-DD',
        description: `How long back to look`,
      },
    },
    sources: ['linkedin'],
  },
  {
    name: 'Series {x} funding announced',
    type: 'campaign_trigger',
    prefill: {
      name: ({ x }) => `Series ${x} Announced`,
      definition: ({ x }) => `Have they announced a Series ${x} funding round.`,
      example: 'Yes',
      time_measure: 'after',
      time_value: ({ timeframe }) => timeframe,
    },
    additional_options: {
      x: {
        input_type: 'text',
        description: `Which Series?`,
        long_description: `For example Seed, A, B`,
      },
      timeframe: {
        input_type: 'date',
        format: 'YYYY-MM-DD',
        description: `How long back to look`,
      },
    },
    sources: ['crunchbase', 'openai'],
  },
  {
    name: 'How many people work for the company?',
    type: 'datapoint',
    prefill: {
      name: 'Headcount',
      definition: 'What is the headcount of the company',
      example: '1022',
    },
    sources: ['linkedin'],
  },
  {
    name: 'Company description',
    type: 'datapoint',
    prefill: {
      name: 'Description',
      definition: 'Description of the company',
      example: '{company_description}',
    },
    sources: ['linkedin'],
  },
  {
    name: "Where is the company's HQ?",
    type: 'datapoint',
    prefill: {
      name: 'HQ Location',
      definition: 'Where is the companies headquarters?',
      example: 'Minnesota',
    },
    sources: ['linkedin', 'google'],
  },
  {
    name: 'Name of person with {job title}',
    type: 'datapoint',
    prefill: {
      name: ({ job_title }) => `${job_title} Name`,
      definition: ({ job_title }) => `What is the name of the ${job_title}`,
      example: 'Jane Smith',
    },
    additional_options: {
      job_title: {
        input_type: 'text',
        description: `The job title you're looking for`,
        long_description: `For example CEO, CFO, Chairman, CTO`,
      },
    },
    sources: ['linkedin', 'google', 'openai'],
  },
  {
    name: 'Most recent funding round',
    type: 'datapoint',
    prefill: {
      name: 'Recent Funding Round',
      definition:
        'How much was raised in the most recent funding round and from which investors?',
      example: '$6M from Sequoia Capital',
    },
    sources: ['crunchbase'],
  },
  {
    name: 'Social media username',
    type: 'datapoint',
    prefill: {
      name: ({ site }) => `${site} handle`,
      definition: ({ site }) => `What is their username on ${site}`,
      example: 'username',
    },
    additional_options: {
      site: {
        input_type: 'text',
        description: 'Which social media site?',
        long_description: 'e.g. Instagram, Twitter etc',
      },
    },
    sources: ['google'],
  },
];

export { predefinedResearchPoints };
