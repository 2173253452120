import React, {
  useState,
  useMemo,
  useEffect,
  memo,
  useCallback,
  useRef,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import ConfirmModal from '../confirm-modals/ConfirmModal';
import api from '../../api';
import { useAuth0 } from '@auth0/auth0-react';
import { usePusher } from '../../contexts/PusherContext';
import EditResearchPointModal from '../analyst/EditResearchPointModal';

import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import './funnel.css';

import Processing from './org-list/Processing';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import 'ag-grid-enterprise';

import './grid.css';
import NameCell from './org-list/NameCell';
import JobData from './org-list/JobData';
import ProspectsData from './org-list/ProspectsData';
import ValueCell from './org-list/ValueCell';
import AnswerError from './org-list/AnswerError';
import AddNewLoading from './org-list/AddNewLoading';
import AddNewRow from './org-list/AddNewRow';
import AddNewRowEdit from './org-list/AddNewRowEdit';
import DiscoveryContext from './org-list/DiscoveryContext';
import LoadingGrid from './LoadingGrid';
import RecentHires from './org-list/RecentHires';

const OrgList = ({
  orgs,
  updateOrgs,
  orgsLoading,
  refreshOrgs,
  view,
  funnelId,
  isImport,
  untether,
  hasFilter,
  additionalKeyChanges,
  researchPointChanges, // We use this to track if there have been changes to research points for the funnel although we retrieve ourself
  addColumnClick,
  setDownloadData,
  setCellForDisplay,
  refreshResearchPoints,
  setSelectedOrgIds,
  handleDelete,
  handleDeleteDatapoints,
  handleAdditionalKeyDelete,
}) => {
  const gridRef = useRef();
  const { getAccessTokenSilently } = useAuth0();
  const pusherChannel = usePusher(); // Get the Pusher channel
  const [isConfirmModalVisible, setConfirmModalVisible] = useState(false);
  const [researchPoints, setResearchPoints] = useState([]);
  const [additionalDataKeys, setAdditionalDataKeys] = useState([]);
  const [researchPointsLoading, setResearchPointsLoading] = useState(true);
  const [importExtraKeys, setImportExtraKeys] = useState(false);
  const [researchPointStatuses, setResearchPointStatuses] = useState([]);
  const [statusesLoading, setStatusesLoading] = useState(true);
  const [optimisticProcessingSet, setOptimisticProcessingSet] = useState([]);
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] =
    useState(false);
  const [selectedResearchPointId, setSelectedResearchPointId] = useState(null);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [editResearchPointId, setEditResearchPointId] = useState(null);
  const [confirmAdditionalKeyDelete, setConfirmAdditionalKeyDelete] =
    useState(null);

  const items = useMemo(() => {
    return isImport && !hasFilter
      ? orgs.searched
      : view === 'filtered'
        ? orgs.filtered
        : orgs.searched;
  }, [orgs, view, isImport, hasFilter]);

  const fetchResearchPoints = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/funnels/${funnelId}/research-points`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const sortedResearchPoints = response.data.sort((a, b) => {
        if (a.position !== b.position) {
          return a.position - b.position;
        }
        return a.name.localeCompare(b.name);
      });

      setResearchPoints(sortedResearchPoints);
    } catch (error) {
      console.error('Error fetching research points:', error);
    } finally {
      setResearchPointsLoading(false);
    }
  };

  useEffect(() => {
    fetchResearchPointStatuses();
  }, [orgs]);

  useEffect(() => {
    // This covers the time when we're doing an import (or agent search) and we need to fetch additional keys again
    // because when we load we have no orgs and therefore no keys so have to wait for the first to come through
    if (items.length > 0 && importExtraKeys === false) {
      fetchAdditionalDataKeys({ suppressEmptyReload: true });
      setImportExtraKeys(true);
    }
  }, [items]);

  // Inside OrgList.js
  const fetchResearchPointStatuses = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(
        `/funnels/${funnelId}/research-point-status`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      setResearchPointStatuses(() => {
        setTimeout(() => {
          setOptimisticProcessingSet([]);
          gridRef?.current?.api.refreshCells({
            force: true,
            suppressFlash: true,
          });
        }, 10);
        return response.data;
      });

      // // Check if any research points are in the 'processing' state
      // const processingJobs = response.data
      //   .flatMap((item) => item.research_points)
      //   .filter((point) => point.status === 'processing')
      //   .flatMap((point) => point.jobDetails);

      // setOldestProcessingJob(
      //   funnelId,
      //   processingJobs.length > 0 ? processingJobs : null,
      // );
    } catch (error) {
      console.error('Error fetching research point statuses:', error);
    } finally {
      setStatusesLoading(false);
    }
  };

  const showDeleteConfirmModal = (researchPointId) => {
    setSelectedResearchPointId(researchPointId);
    setConfirmDeleteModalVisible(true);
  };

  const refreshResearchPointValue = async ({ researchSets }) => {
    try {
      const token = await getAccessTokenSilently();
      setOptimisticProcessingSet((sets) => {
        return [...sets, ...researchSets];
      });
      await api.post(
        `/funnels/refresh-research`,
        {
          researchSets,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (e) {
      alert('There was an error updating your research point');
      console.error(`Error updating research`);
    }
  };

  const editAdditionalDataValue = async ({ key, value, searched_org_id }) => {
    try {
      // Don't need to update the value as that's done by the ag-grid
      const token = await getAccessTokenSilently();
      await api.put(
        `/funnels/${funnelId}/set-ad-value`,
        {
          value,
          searched_org_id,
          key,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (e) {
      alert('There was an error updating your research point');
      console.error(`Error updating research`);
    }
  };

  const editResearchPointValue = async ({
    research_point_id,
    value,
    searched_org_id,
  }) => {
    try {
      // Update the orgs to take account of the new value
      updateOrgs((orgs) => {
        // We only edit the filtered orgs
        let newFiltered = orgs.filtered;
        let newEntry = orgs.filtered.find((x) => x.id === searched_org_id);

        if (newEntry) {
          let newResearchPoint = {
            ...newEntry.research_points.find((x) => x.id === research_point_id),
            edited: true,
            tethered: true,
            value_context: 'Manually edited',
          };

          newEntry = {
            ...newEntry,
            research_points: [
              ...newEntry.research_points.filter(
                (x) => x.id !== research_point_id,
              ),
              newResearchPoint,
            ],
          };

          newFiltered = [
            ...orgs.filtered.filter((x) => x.id !== searched_org_id),
            newEntry,
          ];

          setCellForDisplay({
            markdown: 'Manually edited',
            tethered: true,
            searched_org: newEntry,
            research_point: newResearchPoint,
          });
        }

        return {
          ...orgs,
          filtered: newFiltered,
        };
      });

      // Refresh the relevant cell
      const token = await getAccessTokenSilently();
      await api.put(
        `/funnels/${funnelId}/set-research-value/${research_point_id}`,
        {
          value,
          searched_org_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (e) {
      alert('There was an error updating your research point');
      console.error(`Error updating research`);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const token = await getAccessTokenSilently();
      await api.delete(`/funnels/${funnelId}/research-points`, {
        data: { research_point_id: selectedResearchPointId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Optimistically update the state
      setResearchPoints((prev) =>
        prev.filter((rp) => rp.id !== selectedResearchPointId),
      );
      refreshResearchPoints();
      setConfirmDeleteModalVisible(false); // Hide the confirmation modal
    } catch (error) {
      console.error('Error deleting research point:', error);
    } finally {
      setSelectedResearchPointId(null);
    }
  };

  const handleAnalystStatusUpdate = (data) => {
    const { known_org_id, research_point_id, job, status } = data;

    // Make sure we don't keep any optimistic processing sets that we previously had
    setOptimisticProcessingSet((sets) =>
      sets.filter((setData) => {
        return (
          parseInt(setData.known_org_id, 10) !== parseInt(known_org_id, 10) &&
          parseInt(setData.research_point_id, 10) !==
            parseInt(research_point_id, 10)
        );
      }),
    );

    setResearchPointStatuses((prevRPStatuses) => {
      const newStatuses = prevRPStatuses.map((statusObj) => {
        if (statusObj.known_org_id !== known_org_id) {
          return statusObj;
        }
        const researchPoints = Array.isArray(statusObj.research_points)
          ? statusObj.research_points
          : [];

        const researchPointIndex = researchPoints.findIndex(
          (rp) => rp.id === research_point_id,
        );

        if (researchPointIndex === -1) {
          researchPoints.push({
            id: research_point_id,
            status,
            jobDetails: [job],
          });
        } else {
          researchPoints[researchPointIndex] = {
            id: research_point_id,
            status,
            jobDetails: [job],
          };
        }

        return {
          ...statusObj,
          research_points: researchPoints,
        };
      });

      setTimeout(() => {
        const rowNode = gridRef?.current?.api.getRowNode(
          known_org_id.toString(),
        );
        if (!rowNode) return;
        // This callback runs immediately after the state update
        gridRef?.current?.api.refreshCells({
          force: true,
          rowNodes: [rowNode],
          columns: [
            gridRef.current.api.getColumn(
              `research-point-${research_point_id}`,
            ),
          ],
        });
      }, 100);

      // Return the new state so it's immediately available for further operations
      return newStatuses;
    });
  };

  useEffect(() => {
    fetchResearchPoints();
    fetchResearchPointStatuses();

    if (pusherChannel) {
      pusherChannel.bind('analyst_status_update', handleAnalystStatusUpdate);
      return () => {
        pusherChannel.unbind(
          'analyst_status_update',
          handleAnalystStatusUpdate,
        );
      };
    }
  }, [funnelId, pusherChannel, researchPointChanges]);

  const handleDeleteAdditionalKey = (id) => {
    setConfirmAdditionalKeyDelete(id);
  };
  const handleCheckboxChange = useCallback(({ api }) => {
    setSelectedOrgIds(api.getSelectedNodes().map(({ data }) => data.id));
  });

  const handleCellSelectionChanged = useCallback(({ api }) => {
    const ranges = api.getCellRanges();
    const range = ranges[0]; // We only care about one here.

    if (!range) return;
    const cols = range.columns.map((col) => ({
      id: col?.colDef?.researchPointId || col?.colDef?.additionalDataKey,
      from: col?.colDef?.from,
    }));

    if (cols.length > 1) {
      return;
    }

    if (range.startRow.rowIndex !== range.endRow.rowIndex) {
      return;
    }

    const specialAdditionalContextDisplayFields = [
      'Job Data',
      'Discovery Context',
    ];
    if (
      cols[0].from === 'additionalData' &&
      !specialAdditionalContextDisplayFields.includes(cols[0].id)
    ) {
      setCellForDisplay({
        markdown: 'User defined field',
      });
    } else if (cols[0].id === 'Job Data') {
      setCellForDisplay({
        markdown: 'LinkedIn Data from Search',
      });
    } else if (cols[0].id === 'Discovery Context') {
      const row = api.getDisplayedRowAtIndex(range.startRow.rowIndex);
      const known_org_id = parseInt(row.id, 10);
      const dataRow = relevantItems.find(
        (x) => x.known_org_id === known_org_id,
      );
      const adRow = dataRow?.additional_data?.find(
        (x) => x.key === 'Discovery Context',
      );

      let information = {};
      try {
        information = JSON.parse(adRow?.value);
      } catch (e) {
        // No action
      }

      setCellForDisplay({
        markdown: information.source
          ? `[Source](${information.source}) - ${information.date ? new Date(information.date).toLocaleDateString() : 'No date for source'}`
          : 'No source',
      });
    }

    if (cols[0].from !== 'researchPoint') {
      return;
    }

    // Means we have exactly one selected and it's an RP.
    const row = api.getDisplayedRowAtIndex(range.startRow.rowIndex);
    const known_org_id = parseInt(row.id, 10);
    const research_point_id = cols[0].id;

    const dataRow = relevantItems.find((x) => x.known_org_id === known_org_id);
    const rpRow = dataRow?.research_points?.find(
      (x) => x.id === research_point_id,
    );
    setCellForDisplay({
      markdown: `${rpRow?.value_context} ${rpRow?.last_checked ? `- Last Checked: ${new Date(rpRow.last_checked).toLocaleDateString()} ${new Date(rpRow.last_checked).toLocaleTimeString()}` : ``}`,
      tethered: rpRow?.tethered,
      research_point: rpRow,
      searched_org: dataRow,
    });
  });

  // Create additional data columns based on the keys in the additional_data

  const fetchAdditionalDataKeys = async ({ suppressEmptyReload } = {}) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(
        `/funnels/${funnelId}/additional-data-keys`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      // This is used when we do the CSV/CRM import force reload
      // this prevents the "Add a company" from losing focus unnecessarily
      // which would effect the sense of speed
      if (suppressEmptyReload && !response.data?.keys?.length) {
        return;
      }

      // Optimistically update the state
      setAdditionalDataKeys(response.data?.keys || []);
    } catch (error) {
      console.error('Error deleting research point:', error);
    }
  };

  // Add this function to generate a unique ID
  const generateId = () => '_' + Math.random().toString(36).substr(2, 9);

  const insertNewLoading = async ({ newValue: company_name, ...params }) => {
    try {
      const token = await getAccessTokenSilently();
      const manual_add_id = generateId(); // Generate the manual_add_id on the frontend

      const newOne = {
        name: company_name,
        known_org_id: manual_add_id,
        research_points: [],
        additional_data: [],
        is_loading_row: true,
        active: true,
      };

      updateOrgs((prevOrgs) => {
        const newFiltered = [newOne, ...prevOrgs.filtered];
        const newSearched = [newOne, ...prevOrgs.searched];

        params.api.ensureIndexVisible(0);

        return {
          ...prevOrgs,
          filtered: newFiltered,
          searched: newSearched,
        };
      });

      await api.post(
        `/funnels/${funnelId}/manual-company-add`,
        { company_name, manual_add_id }, // Pass the generated ID to the backend
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (error) {
      console.error('Error deleting research point:', error);
    }
  };

  useEffect(() => {
    fetchAdditionalDataKeys();
  }, [funnelId, additionalKeyChanges]);

  /*
    See cellRendererSelector for why we have useRef
  */
  const researchPointStatusesRef = useRef(researchPointStatuses);
  const optimisticProcessingSetRef = useRef(optimisticProcessingSet);

  useEffect(() => {
    researchPointStatusesRef.current = researchPointStatuses;
  }, [researchPointStatuses]);

  useEffect(() => {
    if (!gridRef) return;
    optimisticProcessingSetRef.current = optimisticProcessingSet;

    gridRef.current?.api?.refreshCells({
      force: true,
      rowNodes: optimisticProcessingSet.map((x) => {
        return gridRef.current.api.getRowNode(x.known_org_id.toString());
      }),
      columns: optimisticProcessingSet.map((x) => {
        return gridRef.current.api.getColumn(
          `research-point-${x.research_point_id}`,
        );
      }),
    });
  }, [optimisticProcessingSet, gridRef]);

  useEffect(() => {
    if (gridRef) {
      setDownloadData(() => () => gridRef.current?.api?.exportDataAsCsv());
    }
  }, [gridRef]);

  const addManuallyAddedCompanies = (
    { searchedOrg, filteredOrg },
    removeOrgId,
  ) => {
    updateOrgs((prevOrgs) => {
      const newFiltered = prevOrgs.filtered.filter((org) => {
        // Removes the loading row
        return org.known_org_id !== removeOrgId;
      });

      const newSearched = prevOrgs.searched.filter((org) => {
        return org.known_org_id !== removeOrgId;
      });

      return {
        filtered: [...newFiltered, filteredOrg],
        searched: [...newSearched, searchedOrg],
      };
    });
  };
  const additionalDataCellRenderer = (key) => {
    if (key === 'Job Data') {
      return 'jobDataRenderer';
    }
    if (key === 'Prospects') {
      return 'prospectsDataRenderer';
    }
    if (key === 'Discovery Context') {
      return 'discoveryContextRenderer';
    }
    return undefined;
  };

  const columns = useMemo(
    () => [
      {
        headerName: 'Name',
        valueGetter: (params) => params.data.name,
        cellRendererSelector: (params) => {
          if (params.data.is_add_new_row) {
            return { component: AddNewRow };
          }

          if (params.data.is_loading_row) {
            return {
              component: AddNewLoading,
              params: { addManuallyAddedCompanies },
            };
          }
          return { component: NameCell };
        },
        cellEditorSelector: (params) => {
          if (params.data.is_add_new_row) {
            return { component: AddNewRowEdit };
          }
          return { component: 'agTextCellEditor' };
        },
        valueSetter: (params) => {
          if (params.data.is_add_new_row && params.newValue) {
            insertNewLoading(params);
          }
        },
        editable: true,
        filter: true,
        flex: 1,
        minWidth: 200,
        colSpan: (params) =>
          params.data.is_add_new_row || params.data.is_loading_row ? 1000 : 1,
        comparator: (valueA, valueB) =>
          valueA.toLowerCase() < valueB.toLowerCase() ? -1 : 1,
      },
      {
        headerName: 'Description',
        valueGetter: (params) => params.data.description,
        editable: true,
        filter: true,
        flex: 2,
        minWidth: 300,
      },
      {
        headerName: 'Website',
        valueGetter: (params) => params.data.website,
        editable: true,
        filter: true,
        flex: 1,
        minWidth: 200,
      },
      ...additionalDataKeys.map((key) => ({
        headerName: key,
        cellRendererSelector: (params) => {
          if (additionalDataCellRenderer(key)) {
            return {
              component: additionalDataCellRenderer(key),
              params: {
                value: params?.data?.additional_data?.find((x) => x.key === key)
                  ?.value,
                row: params.data,
              },
            };
          } else if (key === 'Recent Hires') {
            return {
              component: 'recentHiresRenderer',
              params: {
                value: params?.data?.additional_data?.find((x) => x.key === key)
                  ?.value,
              },
            };
          }
          return {
            component: ValueCell,
            params: { value: params.value },
          };
        },
        cellRenderer: additionalDataCellRenderer(key),
        filter: true,
        colId: `additional-data-${key}`,
        editable: additionalDataCellRenderer(key) ? false : true,
        enableCellChangeFlash: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        additionalDataKey: key,
        from: 'additionalData',
        flex: 1,
        minWidth: 200,
        cellEditorParams: {
          maxLength: 2000,
        },
        valueSetter: (params) => {
          params.data.additional_data = params.data.additional_data
            ? [
                ...params.data.additional_data.filter((ad) => {
                  if (ad.key === key) {
                    return false;
                  }
                  return true;
                }),
                { key, value: params.newValue },
              ]
            : [{ key, value: params.newValue }];

          editAdditionalDataValue({
            key,
            searched_org_id: params.data.id,
            value: params.newValue,
            known_org_id: params.data.known_org_id,
          });

          return true;
        },
        comparator: (rowA, rowB) => {
          if (key === 'Job Data') {
            let jobCountA;
            let jobCountB;
            try {
              jobCountA = rowA.jobData ? JSON.parse(rowA.jobData).length : 0;
              jobCountB = rowB.jobData ? JSON.parse(rowB.jobData).length : 0;
            } catch (e) {
              jobCountA = 0;
              jobCountB = 0;
            }
            return jobCountA < jobCountB ? 1 : -1;
          } else if (key === 'Prospects') {
            let prospectsCountA, prospectsCountB;
            try {
              prospectsCountA = rowA.prospectsData
                ? JSON.parse(rowA.prospectsData).length
                : 0;
              prospectsCountB = rowB.prospectsData
                ? JSON.parse(rowB.prospectsData).length
                : 0;
            } catch (e) {
              prospectsCountA = 0;
              prospectsCountB = 0;
            }
            return prospectsCountA < prospectsCountB ? 1 : -1;
          } else if (key === 'Recent Hires') {
            const parseMonthYear = (dateStr) => {
              if (!dateStr) return new Date(0); // Return earliest possible date if invalid
              const [month, year] = dateStr.split(' ');
              const months = {
                January: 0,
                February: 1,
                March: 2,
                April: 3,
                May: 4,
                June: 5,
                July: 6,
                August: 7,
                September: 8,
                October: 9,
                November: 10,
                December: 11,
              };
              return new Date(parseInt(year), months[month]);
            };

            let dateA, dateB;
            try {
              const hiresA = rowA.recentHireData
                ? JSON.parse(rowA.recentHireData || '[]')
                : [];
              const hiresB = rowB.recentHireData
                ? JSON.parse(rowB.recentHireData || '[]')
                : [];
              dateA = hiresA[0]?.hire_date
                ? parseMonthYear(hiresA[0].hire_date)
                : new Date(0);
              dateB = hiresB[0]?.hire_date
                ? parseMonthYear(hiresB[0].hire_date)
                : new Date(0);
            } catch (e) {
              console.log('here', e);
              dateA = new Date(0);
              dateB = new Date(0);
            }

            // Sort most recent first
            return dateA === dateB ? 0 : dateB - dateA;
          } else {
            if (rowA === null && rowB !== null) return 1; // Null is "greater" than non-null
            if (rowB === null && rowA !== null) return -1; // Non-null is "lesser" than null
            if (rowA < rowB) return -1;
            if (rowA > rowB) return 1;
            return 0; // Handle equality
          }
        },
        valueGetter: (params) => {
          const val = params.data.additional_data.find(
            (data) => data.key === key,
          );
          const match = {
            'Job Data': 'jobData',
            Prospects: 'prospectsData',
            'Discovery Context': 'discoveryContextData',
          };

          if (key === 'Recent Hires') {
            try {
              const valParsed = JSON.parse(val.value);
              return `${valParsed[0]?.name} joined ${valParsed[0]?.hire_date} as ${valParsed[0]?.job_title}`;
            } catch {
              return ``;
            }
          } else if (key === 'Job Data') {
            return `Job Data`;
          } else if (key === 'Prospects') {
            return `Prospects`;
          } else if (key === 'Discovery Context') {
            try {
              const valParsed = JSON.parse(val.value);
              return `${valParsed.reason}`;
            } catch {
              return ``;
            }
          }

          return val?.value;
        },
      })),
      ...(isImport || view === 'filtered'
        ? researchPoints.map((rp) => ({
            colId: `research-point-${rp.id}`,
            headerName: rp.name,
            filter: true,
            editable: true,
            enableCellChangeFlash: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            researchPointId: rp.id,
            from: 'researchPoint',
            flex: 1,
            minWidth: 200,
            cellEditorParams: {
              maxLength: 2000,
            },
            valueSetter: (params) => {
              params.data.research_points = params.data.research_points?.map(
                (researchPoint) => {
                  if (researchPoint.id === rp.id) {
                    return {
                      ...researchPoint,
                      value: params.newValue,
                    };
                  }
                  return researchPoint;
                },
              );

              editResearchPointValue({
                research_point_id: rp.id,
                searched_org_id: params.data.id,
                value: params.newValue,
                known_org_id: params.data.known_org_id,
              });

              return true;
            },
            valueGetter: (params) => {
              const point = params.data.research_points?.find(
                (r) => r.id === rp.id,
              );

              if (!point) {
                return 'NO_SUCH_POINT';
              }

              return point?.value;
            },
            cellRendererSelector: ({ data, value }) => {
              // We use refs here because the memo-ization
              // messes with state references and we can't add these variables
              // into the on change lists because it will redefine the structure of the table
              // everytime a status changes
              const researcherStatusList = researchPointStatusesRef.current;
              const optimisticProcessing = optimisticProcessingSetRef.current;

              const orgStatus = researcherStatusList.find(
                (status) => status.searched_org_id === data.id,
              );
              const status = orgStatus
                ? orgStatus.research_points.find((rps) => rps.id === rp.id)
                : null;

              const isOptimisticProcessing = optimisticProcessing.find(
                ({ known_org_id, research_point_id }) => {
                  return (
                    known_org_id === data.known_org_id &&
                    research_point_id === rp.id
                  );
                },
              );

              if (status?.status === 'processing' || isOptimisticProcessing) {
                return {
                  component: Processing,
                  params: { statusObject: status, id: data.id },
                };
              } else if (status?.status === 'error' && !value) {
                return { component: AnswerError };
              } else if (value === 'NO_SUCH_POINT') {
                return { component: ValueCell, params: { noValueFound: true } };
              }

              const researchPoint = data.research_points?.find(
                (r) => r.id === rp.id,
              );

              return {
                component: ValueCell,
                params: { tethered: researchPoint?.tethered },
              };
            },
          }))
        : []),
      {
        headerName: 'Add Column',
        headerComponent: () => (
          <div className='add-column-header' onClick={addColumnClick}>
            Add column{' '}
            <div className='red-icon'>
              <PlusIcon />
            </div>
          </div>
        ),
        width: 200,
        cellRenderer: () => {
          return (
            <div
              className='new-column-add-clicker'
              onClick={() => addColumnClick()}
            ></div>
          );
        },
      },
    ],
    [researchPoints, additionalDataKeys, isImport, view],
  );

  const getRowId = useCallback(
    // Occasionally when uploading a CSV we might not yet have a known org.
    (params) => {
      return params.data.known_org_id
        ? params.data.known_org_id.toString()
        : params.data.id.toString();
    },
    [],
  );

  const [relevantItems, setRelevantItems] = useState(
    items.filter((x) => x.active).sort((a, b) => b.id - a.id),
  );

  useEffect(() => {
    setRelevantItems(items.filter((x) => x.active).sort((a, b) => b.id - a.id));
  }, [items]);

  const components = useMemo(() => {
    return {
      nameRenderer: memo(NameCell),
      jobDataRenderer: memo(JobData),
      prospectsDataRenderer: memo(ProspectsData),
      discoveryContextRenderer: memo(DiscoveryContext),
      recentHiresRenderer: memo(RecentHires),
    };
  }, [additionalDataKeys]);

  const rowSelection = useMemo(() => {
    return isImport || view === 'filtered'
      ? {
          mode: 'multiRow',
          hideDisabledCheckboxes: true,
          isRowSelectable: (rowNode) => {
            return rowNode.data
              ? !rowNode.data.is_add_new_row && !rowNode.data.is_loading_row
              : true;
          },
        }
      : {};
  }, [isImport, view]);

  const selection = useMemo(() => {
    return isImport || view === 'filtered' ? { mode: 'cell' } : {};
  }, [isImport, view]);

  const cellSelection = useMemo(() => {
    return isImport || view === 'filtered'
      ? { handle: { mode: 'range' }, suppressMultiRanges: true }
      : {};
  }, [isImport, view]);

  const getCurrentCells = (params, filterTethered) => {
    const range = params.api.getCellRanges()[0]; // We suppress multiple so there's only one
    if (!range) return [];
    const cols = range.columns.map((col) => ({
      id: col?.colDef?.researchPointId,
      from: col?.colDef?.from,
    }));

    const startRowIndex = Math.min(
      range.startRow.rowIndex,
      range.endRow.rowIndex,
    );
    const endRowIndex = Math.max(
      range.startRow.rowIndex,
      range.endRow.rowIndex,
    );

    let sets = [];
    for (let i = startRowIndex; i <= endRowIndex; i++) {
      const row = params.api.getDisplayedRowAtIndex(i);
      const rowId = row.id;
      cols.forEach((col) => {
        if (col.from === 'researchPoint') {
          sets.push({
            known_org_id: parseInt(rowId, 10),
            research_point_id: col.id,
          });
        }
      });
    }

    if (filterTethered) {
      let tethered = [];
      orgs.filtered.forEach((org) => {
        org.research_points?.forEach((rp) => {
          if (rp.tethered) {
            tethered.push({
              research_point_id: rp.id,
              known_org_id: org.known_org_id,
            });
          }
        });
      });

      sets = sets.filter(({ research_point_id, known_org_id }) => {
        const foundMatch = tethered.find((tether) => {
          return (
            tether.research_point_id === research_point_id &&
            tether.known_org_id === known_org_id
          );
        });
        return foundMatch ? false : true;
      });
    }

    return sets;
  };

  const getMainMenuItems = useCallback((params) => {
    const isResearchPoint = params?.column?.colDef?.researchPointId;
    const isAdditionalData = params?.column?.colDef?.additionalDataKey;

    const opts = params.defaultItems;

    if (isResearchPoint) {
      opts.push(
        ...[
          'separator',
          {
            name: 'Edit Research',
            cssClasses: ['research-red-background'],
            action: () => {
              setEditResearchPointId(isResearchPoint);
              setEditModalVisible(true);
            },
          },
          {
            name: 'Remove Research',
            cssClasses: ['research-red-background'],
            action: () => {
              handleDeleteDatapoints([isResearchPoint]);
            },
          },
        ],
      );
    }

    if (isAdditionalData) {
      opts.push(
        ...[
          'separator',
          {
            name: 'Remove Column',
            action: () => handleDeleteAdditionalKey(isAdditionalData),
          },
        ],
      );
    }
    return params.defaultItems;
  });
  const getContextMenuItems = useCallback(
    (params) => {
      const getCellCount = () => {
        const range = params.api.getCellRanges()[0]; // We suppress multiple so there's only one
        if (!range) return 0;
        const columns = range.columns.length;
        const startRowIndex = Math.min(
          range.startRow.rowIndex,
          range.endRow.rowIndex,
        );
        const endRowIndex = Math.max(
          range.startRow.rowIndex,
          range.endRow.rowIndex,
        );

        return columns * (endRowIndex + 1 - startRowIndex);
      };
      const extraCellsLang = `${getCellCount() > 1 ? `these ${getCellCount()} cells` : `this cell`}`;
      const triggerResearch = {
        name: `Update data for ${extraCellsLang}`,
        action: () => {
          refreshResearchPointValue({
            researchSets: getCurrentCells(params, true),
          });
        },
        icon: `
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
          >
            <path
              d='M7.5 6.25195H4.24062C6.31062 3.07008 10.5681 2.16883 13.75 4.23883C15.6994 5.50758 16.8756 7.6757 16.875 10.002H18.125C18.1231 5.5132 14.4888 1.87508 10 1.87695C7.5875 1.87758 5.29375 2.9507 3.75 4.80508V2.50195H2.5V7.50195H7.5V6.25195Z'
              fill='#5C6880'
            />
            <path
              d='M12.5 13.748H15.7594C13.6894 16.9299 9.43188 17.8312 6.25 15.7612C4.30063 14.4924 3.12437 12.3243 3.125 9.99805H1.875C1.87688 14.4868 5.51125 18.1249 10 18.123C12.4125 18.1224 14.7063 17.0493 16.25 15.1949V17.498H17.5V12.498H12.5V13.748Z'
              fill='#5C6880'
            />
          </svg>
        `,
      };

      const unlock = {
        name: `Unlock ${extraCellsLang}`,
        action: () => {
          untether(getCurrentCells(params));
        },
        icon: `
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.25 9.75H18.75C19.1478 9.75 19.5294 9.90804 19.8107 10.1893C20.092 10.4706 20.25 10.8522 20.25 11.25V22.5H3.75V11.25C3.75 10.8522 3.90804 10.4706 4.18934 10.1893C4.47064 9.90804 4.85218 9.75 5.25 9.75H6.75V9C6.75 7.60761 7.30312 6.27226 8.28769 5.28769C9.27226 4.30312 10.6076 3.75 12 3.75C13.3924 3.75 14.7277 4.30312 15.7123 5.28769C16.6969 6.27226 17.25 7.60761 17.25 9V9.75ZM9.34835 6.34835C8.64509 7.05161 8.25 8.00544 8.25 9V9.75H15.75V9C15.75 8.00544 15.3549 7.05161 14.6517 6.34835C13.9484 5.64509 12.9946 5.25 12 5.25C11.0054 5.25 10.0516 5.64509 9.34835 6.34835ZM5.25 11.25V21H18.75V11.25H5.25ZM11.4697 13.7197C11.6103 13.579 11.8011 13.5 12 13.5C12.1989 13.5 12.3897 13.579 12.5303 13.7197C12.671 13.8603 12.75 14.0511 12.75 14.25V18.75H11.25V14.25C11.25 14.0511 11.329 13.8603 11.4697 13.7197Z" fill="#5C6880"/>
</svg>
        `,
      };

      return [
        'copy',
        'separator',
        triggerResearch,
        unlock,
        'separator',
        'csvExport',
        'excelExport',
      ];
    },
    [window, orgs],
  );

  if (statusesLoading || orgsLoading || researchPointsLoading) {
    return <LoadingGrid />;
  }

  return (
    <div className='orgs-area dream-table-wrapper'>
      <div className={`dream-table-container ag-theme-quartz`}>
        <AgGridReact
          rowData={[...relevantItems]}
          pinnedBottomRowData={[
            {
              name: 'Add new',
              is_add_new_row: true,
              known_org_id: 'add-new-row',
              research_points: [],
              additional_data: [],
            },
          ]}
          columnDefs={columns}
          domLayout={relevantItems.length > 11 ? 'normal' : 'autoHeight'}
          animateRows={true}
          getRowId={getRowId}
          components={components}
          rowSelection={rowSelection}
          selection={selection}
          cellSelection={cellSelection}
          ref={gridRef}
          onSelectionChanged={handleCheckboxChange}
          onCellSelectionChanged={handleCellSelectionChanged}
          rowBuffer={100}
          getContextMenuItems={getContextMenuItems}
          getMainMenuItems={getMainMenuItems}
          suppressColumnVirtualisation
          suppressNoRowsOverlay
          useCallback
        />
      </div>

      <ConfirmModal
        show={isConfirmModalVisible}
        handleClose={() => setConfirmModalVisible(false)}
        handleConfirm={handleDelete}
        confirmIsPrimary={false} // Use 'danger' as the variant for delete action
        title='Confirm Deletion'
        bodyText={
          <>
            <p>Are you sure you want to delete the selected organizations?</p>
          </>
        }
      />

      <ConfirmModal
        show={confirmDeleteModalVisible}
        handleClose={() => setConfirmDeleteModalVisible(false)}
        handleConfirm={handleConfirmDelete}
        title='Confirm Deletion'
        bodyText={<>Are you sure you want to delete this information?</>}
        confirmLabel='Delete'
        cancelLabel='Cancel'
      />

      <ConfirmModal
        show={confirmAdditionalKeyDelete}
        handleClose={() => setConfirmAdditionalKeyDelete(null)}
        handleConfirm={() => {
          handleAdditionalKeyDelete(confirmAdditionalKeyDelete);
          setAdditionalDataKeys((prev) => {
            return prev.filter((x) => x !== confirmAdditionalKeyDelete);
          });
          setConfirmAdditionalKeyDelete(null);
        }}
        title='Confirm deletion'
        bodyText={
          <>
            <p>Are you sure you want to remove this column?</p>
            <br />
            <p>
              Removing this column will <b>NOT</b> delete the underlying data
              (to protect any on-going campaigns). Instead you will simply
              no-longer see the data in this list.
            </p>
            <br />
            <p>
              If you wish to delete the underlying data then clear all columns.
            </p>
          </>
        }
        confirmLabel='Delete'
        cancelLabel='Cancel'
      />

      {isEditModalVisible && (
        <EditResearchPointModal
          show={isEditModalVisible}
          researchPointId={editResearchPointId}
          funnelId={funnelId}
          onClose={() => {
            setEditModalVisible(false);
            refreshOrgs();
            refreshResearchPoints();
            fetchResearchPoints(); // Refresh research points after editing
          }}
        />
      )}
    </div>
  );
};

export default OrgList;
