import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { format } from 'date-fns';
import api from '../../api';
import ApprovalModal from './ApprovalModal';
import EscalationModal from './EscalationModal';
import './task-list.css';
import FakePic from '../feed/items/FakePic';
import { usePusher } from '../../contexts/PusherContext';
import { ReactComponent as HornIcon } from '../../icons/horn.svg';
import TaskCountTag from '../misc/TaskCountTag';

function TaskList({ campaignId, fillNumber }) {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [campaignStatuses, setCampaignStatuses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isApproval, setIsApproval] = useState(false);
  const [taskLadder, setTaskLadder] = useState(null);

  const pusherChannel = usePusher();

  // Ensures we don't show loading states for the reloads that happen by interval
  const hasShownLoadingRef = useRef(false);

  useEffect(() => {
    // We want to reset this when we get a new campaign
    hasShownLoadingRef.current = false;
  }, [campaignId]);

  useEffect(() => {
    if (!pusherChannel) return;

    pusherChannel.bind('oagent_status_update', () => {
      fetchTasks();
      fetchCampaignStatuses();
    });

    return () => {
      if (pusherChannel) {
        pusherChannel.unbind('oagent_status_update');
      }
    };
  }, [pusherChannel]);

  const fetchTasks = async () => {
    if (!hasShownLoadingRef.current) {
      setLoading(true);
    }

    try {
      const token = await getAccessTokenSilently();
      const [approvals, escalations] = await Promise.all([
        api.get('/approvals', {
          params: { campaign_id: campaignId },
          headers: { Authorization: `Bearer ${token}` },
        }),
        api.get('/escalations', {
          params: { campaign_id: campaignId },
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);

      const processedApprovals = approvals.data.map((item) => ({
        ...item,
        type: 'approval',
        date: new Date(item.scheduled_time),
      }));

      const processedEscalations = escalations.data.map((item) => ({
        ...item,
        type: 'escalation',
        date: new Date(item.created_at),
      }));

      const mergedTasks = [...processedApprovals, ...processedEscalations].sort(
        (a, b) => a.date - b.date,
      );
      setTasks(mergedTasks);
      fillNumber(mergedTasks.length);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    } finally {
      setLoading(false);
      hasShownLoadingRef.current = true;
    }
  };

  const fetchCampaignStatuses = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get('/actions-required', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCampaignStatuses(response.data);
    } catch (error) {
      console.error('Error fetching campaign statuses:', error);
    }
  };

  useEffect(() => {
    fetchTasks();
    fetchCampaignStatuses();

    const intervalId = setInterval(() => {
      fetchTasks();
      fetchCampaignStatuses();
    }, 60000); // Reload tasks and statuses every 60 seconds

    return () => clearInterval(intervalId); // Clear the interval when the component is unmounted
  }, [campaignId]);

  const openModal = (task, next) => {
    setSelectedTask(task);
    setIsApproval(task.type === 'approval');
    setTaskLadder(tasks.slice(next));
  };

  const closeModal = () => {
    setSelectedTask(null);
    fetchTasks(); // Refresh the task list
    fetchCampaignStatuses(); // Refresh the campaign statuses
  };

  const chooseNext = () => {
    const hasNextTask = taskLadder.length > 1;
    if (hasNextTask) {
      setTaskLadder((prev) => {
        setSelectedTask(taskLadder[1]);
        return prev.slice(1);
      });

      return true;
    } else {
      closeModal();
      fetchTasks();
      return false;
    }
  };

  // Filter tasks based on type
  const approvalTasks = tasks
    .filter((task) => task.type === 'approval')
    .sort((a, b) => {
      if (a.is_response === b.is_response) {
        return 0;
      }
      if (a.is_response) {
        return -1;
      }
      return 1;
    });
  const escalationTasks = tasks.filter((task) => task.type === 'escalation');

  return (
    <div className={`task-list-outer-wrapper ${loading ? 'loading' : ''}`}>
      <div className='header'>
        <h4>Actions </h4>
        <TaskCountTag campaignId={campaignId} />
      </div>
      <div className='task-list-wrapper'>
        {
          <>
            <>
              <>
                <h6>Approvals</h6>
                {approvalTasks.length === 0 && (
                  <div className='empty-state'>
                    <span>No approvals</span>
                  </div>
                )}
                {approvalTasks.slice(0, 3).map((item, i) => (
                  <div
                    className='task-item'
                    key={item.id}
                    onClick={() => openModal(item, i)}
                  >
                    <FakePic url={item.prospects[0].logo_url} />
                    <div className='left-side'>
                      <p>
                        {item.is_response
                          ? `Reply to ${item.prospects[0].name}`
                          : `Outreach to ${item.prospects[0].name}`}
                      </p>
                      <small>{format(item.date, 'PPpp')}</small>
                    </div>
                  </div>
                ))}
              </>
              <>
                <h6>Escalations</h6>
                {escalationTasks.length === 0 && (
                  <div className='empty-state'>
                    <span>No escalations</span>
                  </div>
                )}
                {escalationTasks.slice(0, 3).map((item) => (
                  <div
                    className='task-item'
                    key={item.id}
                    onClick={() => openModal(item)}
                  >
                    <FakePic url={item.logo_url} />
                    <div className='left-side'>
                      <p>{`Reply from ${item.prospects[0].name}`}</p>
                      <small>{format(item.date, 'PPpp')}</small>
                    </div>
                  </div>
                ))}
              </>
            </>
            {!campaignId && (
              <>
                <h6>Campaigns</h6>
                {campaignStatuses.length > 0 ? (
                  campaignStatuses
                    .filter((x) => x.action_state !== 'NO_ACTION')
                    .slice(0, 1)
                    .map((campaign) => {
                      return (
                        <div
                          className='task-item campaign-action'
                          key={campaign.id}
                          onClick={() => navigate(`/campaign/${campaign.id}`)}
                        >
                          <div className='horn-holder'>
                            <HornIcon />
                          </div>
                          <div className='left-side'>
                            <p>
                              {campaign.action_state === 'IMPORTING' && (
                                <>
                                  <b>{campaign.name}</b> is importing prospects
                                </>
                              )}
                              {campaign.action_state === 'DRAFT' && (
                                <>
                                  Complete details for <b>{campaign.name}</b>
                                </>
                              )}
                              {campaign.action_state === 'GENERATE_EMAIL' && (
                                <>
                                  Generate your first email for{' '}
                                  <b>{campaign.name}</b>
                                </>
                              )}
                              {campaign.action_state ===
                                'AUTOPILOT_SWITCH_ON' && (
                                <>
                                  Turn on autopilot for <b>{campaign.name}</b>
                                </>
                              )}
                            </p>
                          </div>
                        </div>
                      );
                    })
                ) : (
                  <div className='empty-state'>
                    <span>No campaigns requiring attention</span>
                  </div>
                )}
              </>
            )}
          </>
        }

        {isApproval ? (
          <ApprovalModal
            show={!!selectedTask}
            onHide={closeModal}
            task={selectedTask}
            chooseNext={chooseNext}
          />
        ) : (
          <EscalationModal
            show={!!selectedTask}
            onHide={closeModal}
            task={selectedTask}
            chooseNext={chooseNext}
          />
        )}
      </div>
      <div
        className='view-all-wrapper'
        onClick={() =>
          navigate(campaignId ? `/task-list/${campaignId}` : '/task-list')
        }
      >
        <span>View all</span>
      </div>
    </div>
  );
}

export default TaskList;
