import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function ConfirmModal({
  show,
  handleClose,
  handleConfirm,
  title,
  bodyText,
  confirmLabel,
  verticalButtons,
  cancelLabel,
  confirmDisabled,
  ...rest
}) {
  return (
    <Modal show={show} onHide={handleClose} {...rest}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{bodyText}</Modal.Body>
      <Modal.Footer>
        <div
          className={`confirm-modal-button-wrapper ${verticalButtons ? 'vertical' : ''}`}
        >
          <Button variant='outline-primary' onClick={handleClose}>
            {cancelLabel || 'Cancel'}
          </Button>
          <Button
            variant='primary'
            onClick={handleConfirm}
            disbled={confirmDisabled}
          >
            {confirmLabel || 'Confirm'}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;
